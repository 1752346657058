<template>
    <div class="advantages-list">
        <template v-if="isFullPackageSelected && !isModulePagePayment">
            <div class="advantages-list__item"
                 v-if="!isLandingPageCoursePayment && meditationInfo.meditation.length"
                 @click="showDialog('meditation-list')"
            >
                <span class="advantages-list-text">
                    {{meditationCount}}
                    {{wordForm(meditationCount, [' Медитация', ' Медитации', ' Медитаций'])}}
                </span>
                <span class="advantages-list__icon"></span>
            </div>
            <div class="advantages-list__item"
                 v-if="!isLandingPageCoursePayment && meditationInfo.webinars.length"
                 @click="showDialog('webinar-list')"
            >
                <span class="advantages-list-text" >
                    {{webinarCount}}
                    {{wordForm(webinarCount, [' Вебинар', ' Вебинара', ' Вебинаров'])}}
                </span>
                <span class="advantages-list__icon"></span>
            </div>

            <div class="advantages-list__item"
                 @click="showDialog('features-meditation')"
                 v-if="meditationInfo.hasPayed
                 && !isLandingPageCoursePayment
                 && !isConsultationPayment
"
            >
                <span class="advantages-list-text">Платите только за новые товары</span>
                <span class="advantages-list__icon"></span>
            </div>
            <div class="advantages-list__item"
                 v-if="isLandingPageCoursePayment && landingPaymentType === 'premium'"
            >
                <span class="advantages-list-text">3 консультации психолога</span>
            </div>
            <div class="advantages-list__item"
                 @click="showDialog('telegram-access')"
                 v-if="!generalMeditationInfo.hasPayed"
            >
                <span class="advantages-list-text">Группа телеграм в подарок</span>
                <span class="advantages-list__icon"></span>
            </div>
        </template>
        <template v-else-if="isModulePagePayment">
            <div class="advantages-list__item"
                 @click="showDialog('modules-list')"
            >
                <span class="advantages-list-text">1 Модуль</span>
                <span class="advantages-list__icon"></span>
            </div>
            <div class="advantages-list__item"
                 v-if="isModulePaymentFull"
                 @click="showDialog('telegram-access')"
            >
                <span class="advantages-list-text">Группа телеграм в подарок</span>
                <span class="advantages-list__icon"></span>
            </div>
        </template>
       <template v-else="">
           <div class="advantages-list__item"
                v-if="isLandingPageCoursePayment && landingPaymentType === 'premium'"
           >
               <span class="advantages-list-text">3 консультации психолога</span>
           </div>
           <div class="advantages-list__item">
               <span class="advantages-list-text">{{descrData.name}}</span>
           </div>
           <div class="advantages-list__item"
                v-if="!generalMeditationInfo.hasPayed"
                @click="showDialog('telegram-access')"
           >
               <span class="advantages-list-text">Группа телеграм в подарок</span>
               <span class="advantages-list__icon"></span>
           </div>
       </template>
        <div class="advantages-list__item"
             @click="showDialog('unlimited-access')"
             v-if="!isConsultationPayment"
        >
            <span class="advantages-list-text">Доступ не ограничен по времени</span>
            <span class="advantages-list__icon"></span>
        </div>
    </div>

</template>

<script>

export default {
  name: 'AdvantagesList',
  components: {},
  data() {
    return {
      complete: false,
    };
  },
  beforeMount() {
    console.log(`isFullPackageSelected: ${this.isFullPackageSelected}`);
  },
  computed: {
    getStripeKey() {
      return 'pk_test_EbFCyRpagCLakgHBurKM1Hkk';
    },
    isFullPackageSelected() {
      return this.$store.state.payment.isFullPackageSelected;
    },
    meditationInfo() {
      return this.$store.getters.getMeditationsInfo;
    },
    generalMeditationInfo() {
      return this.$store.getters.getGeneralMeditationInfo;
    },
    descrData() {
      return this.$store.state.meditations.currentMeditation;
    },
    meditationCount() {
      return this.meditationInfo.meditation.length;
    },
    webinarCount() {
      return this.meditationInfo.webinars.length;
    },
    isLandingPageCoursePayment() {
      return this.$store.getters.isLandingPageCoursePayment;
    },
    landingPaymentType() {
      return this.$store.state.payment.landingPaymentType;
    },
    isModulePagePayment() {
      return this.$store.state.payment.isModulePagePayment;
    },
    isModulePaymentFull() {
      return this.$store.state.payment.isModulePaymentFull;
    },
    isConsultationPayment() {
      return this.$store.state.payment.isConsultationPayment;
    },
  },
  methods: {
    showDialog(name) {
      this.$store.commit('setCurrentTemplate', name);
      this.$store.commit('showDialog');
    },
    wordForm(num, word) {
      const cases = [2, 0, 1, 1, 1, 2];
      return word[(num % 100 > 4 && num % 100 < 20) ? 2 : cases[(num % 10 < 5) ? num % 10 : 5]];
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../assets/scss/common/variables";

.advantages-list {
    margin: 0 0 24px 0;

    &__item {
        background: url("/images/check-iocn-black.svg") no-repeat 0 50%;
        padding-left: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0;
    }

    &__icon {
        background: url("/images/info-icon-black.svg") no-repeat 50% 50%;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}
</style>
