<template>
    <div class="card-form">
        <form action="/charge" method="post" id="payment-form-audio">
            <card class='stripe-card'
                  :class='{ complete }'
                  :stripe='getStripeKey'
                  :options='stripeOptions'
                  @change='change($event)'
            />
            <div class="card-form__action-buttons">
                <!--<button class="es-button es-button&#45;&#45;link"
                @click.prevent='hideStripe'>-->
                    <!--Назад-->
                <!--</button>-->
                <button class="es-button blue" :disabled="loading" @click.prevent='pay'>
                    Оплатить
                </button>
            </div>

            <div
                    class="card-form__package-motivation"
                    v-if="!isFullPackageSelected"
                    @click="moveToFullCourse()"
            >
                {{getPackageMotivationTitle}} {{getPackagePrice}} руб
            </div>
        </form>
    </div>
</template>

<script>
import { Card, createToken } from 'vue-stripe-elements-plus';
import axios from 'axios';

export default {
  name: 'CardForm',
  props: {
    fullPackage: Boolean,
  },
  components: { Card },
  data() {
    return {
      complete: false,
      loading: false,
      stripeOptions: {
        hidePostalCode: true,
        style: {
          base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
              color: '#aab7c4',
            },
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
          },
        },
      },
    };
  },
  computed: {
    getStripeKey() {
      let apiKey = '';
      if (window.location.host === 'elena.shop') {
        apiKey = 'pk_live_tCOquVlyrWmmruTCKLtLHFSW';
      } else {
        apiKey = 'pk_test_EbFCyRpagCLakgHBurKM1Hkk';
      }

      return apiKey;
    },
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
    getPandingMeditations() {
      return this.$store.getters.getPandingMeditations;
    },
    descrData() {
      return this.$store.state.meditations.currentMeditation;
    },
    isSingleMeditation() {
      return this.$route.name === 'Finalprice';
    },
    singleMeditationsCount() {
      return this.$store.getters.getMeditationsInfoForSingle.pendingMeditations.length;
    },
    meditationInfo() {
      return this.$store.getters.getMeditationsInfo;
    },
    meditationInfoCourse() {
      return this.$store.getters.getMeditationsInfoForCourse;
    },
    isFullPackageSelected() {
      return this.$store.state.payment.isFullPackageSelected;
    },
    currentMeditationGroup() {
      return this.$store.state.meditations.currentMeditationGroup;
    },
    getPackagePrice() {
      const discount = this.currentMeditationGroup.saleForAll / 100;
      const fullPrice = this.meditationInfo.priceForNoPayed;

      return fullPrice - (fullPrice * discount);
    },
    priceToPay() {
      return this.$store.state.payment.priceToPay;
    },
    paymentCarrency() {
      return this.$store.state.payment.paymentCarrency;
    },
    getPackageMotivationTitle() {
      return this.meditationInfo.hasPayed ? 'Или докупите весь курс за' : 'Или купить весь курс за';
    },
    getTitle() {
      let title = this.descrData.type === 'mediatation' ? 'Купить медитацию за' : 'Купить вебинар за';

      if (this.isFullPackageSelected) {
        title = 'Купить курс за';
      }

      if (this.isModulePagePayment) {
        title = 'Купить модуль за';
      }

      if (this.isSingleMeditation) {
        title = 'Купить медитацию за';
      }

      if (this.isSingleMeditation && this.singleMeditationsCount > 1) {
        title = 'Купить медитации за';
      }

      return title;
    },
    isLandingPageCoursePayment() {
      return this.$store.getters.isLandingPageCoursePayment;
    },
    coursePaymentType() {
      return this.$store.state.payment.landingPaymentType;
    },
    isModulePagePayment() {
      return this.$store.state.payment.isModulePagePayment;
    },
  },
  mounted() {
    const currentUrl = this.$store.getters.getLocalUrl;

    this.$store.commit('setCardErrorText', '');

    const initiateEventID = Math.floor(Math.random() * (1e12 + 1));

    axios
      .post(`${currentUrl}/v2/api/sendFBServerEvent.php`, {
        amount: this.priceToPay,
        eventName: '​​InitiateCheckout',
        email: this.getUserMail,
        id: initiateEventID,
      });
  },
  methods: {
    hideStripe() {
      this.$store.commit('hideStripePay');
    },
    scrollToTop() {
      document.querySelector('.base-payment__content-scroll').scrollTo(0, 0);
    },
    moveToFullCourse() {
      this.$store.commit('setPackagesSwithcer', true);
      this.$store.commit('markMeditationForPay', {
        isFullPackageSelected: true,
      });
      this.scrollToTop();
    },
    change($event) {
      this.complete = $event.complete;

      if ($event.error) {
        this.$store.commit('setCardErrorText', $event.error.message);
      } else {
        this.$store.commit('setCardErrorText', '');
      }
    },
    pay() {
      this.loading = true;

      // createToken returns a Promise which resolves in a result object with
      // either a token or an error key.
      // See https://stripe.com/docs/api#tokens for the token object.
      // See https://stripe.com/docs/api#errors for the error object.
      // More general https://stripe.com/docs/stripe.js#stripe-create-token.
      //
      createToken().then((data) => {
        if (!data.error) {
          this.$store.commit('setCardErrorText', '');
          const currentUrl = this.$store.getters.getLocalUrl;
          const purchaseEventID = Math.floor(Math.random() * (1e12 + 1));

          axios
            .post(`${currentUrl}/v2/api/stripePayment.php`, {
              amount: this.priceToPay,
              token: data.token.id,
              email: this.getUserMail,
              promoCode: this.$store.state.payment.activatedPromoCode,
              coursePaymentType: this.coursePaymentType,
              fbEventId: purchaseEventID,
              currency: this.paymentCarrency,
            })
            .then((response) => {
              if (response.data.success) {
                this.loading = false;

                if (this.$route.name === 'WebinarLanding') {
                  this.$store.dispatch('addUserToWebinar');
                  this.$router.push({ name: 'WebinarFlowSuccess' });
                  this.$store.dispatch('hidePayment');

                  return;
                }

                if (this.$route.name === 'TelegramMeeting') {
                  this.$store.dispatch('saveTelegramUser');
                  this.$router.push({ name: 'TelegramFlowSuccess' });

                  return;
                }

                if (this.$route.name === 'ConsultatantPayment') {
                  this.$store.dispatch('hidePayment');
                  this.$router.push({ name: 'ConsultatantPayment', query: { success: 'true' } });

                  return;
                }

                this.$store.commit('setFBPurchaseEventID', purchaseEventID);
                this.$store.dispatch('savePayedData');

                this.$store.dispatch('showSuccessResult');
                this.$store.dispatch('hidePayment');
                this.$store.commit('hidePlayer');

                if (this.meditationInfoCourse.priceForNoPayed === 0) {
                  this.$router.push({ name: 'CabinetFull' });
                  return;
                }

                if (this.isLandingPageCoursePayment || this.isModulePagePayment) {
                  this.$router.push({ name: 'Cabinet' });
                }
              } else if (response.data.error) {
                this.$store.commit('setCardErrorText', response.data.errorMessage);
              } else {
                this.$store.commit('setCardErrorText', 'Ваша карта не подходит. Оплатите картой Visa или Mastercard.');
              }
            })
            .finally(() => {
              this.loading = false;
              return false;
            });
        } else {
          if (data.error) {
            this.$store.commit('setCardErrorText', data.error.message);
          }

          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
 @import "../../assets/scss/common/variables";
 @import "../../assets/scss/common/forms";

.card-form {
  margin: 20px 0 0;

    .stripe-card .StripeElement {
        background: $white;
        padding: 12px ;
        height: 20px;
        border-radius: 10px;
        border: solid $darkGray 1px;
        margin-bottom: 20px;

        &--invalid {
            border: solid $main-red 1px;
        }
    }

    &__package-motivation {
        color: $black;
        line-height: 20px;
        font-size: 12px;
        height: 20px;
        text-align: center;
        margin-top: 20px;
        text-decoration: underline;
        cursor: pointer;
    }

    &__action-buttons {
        display: flex;

        .es-button--link {
            color: $main-blue;
            text-decoration: none;
            text-align: left;
            width: 40%;

            &:before {
                display: inline-block;
                background: url("/images/blue-arrow.svg") 50% 50%  no-repeat;
                width: 10px;
                height: 10px;
                margin-right: 5px;
                content: '';
            }
        }
    }

}
</style>
