<template>
    <div class="select-payment">
        <div class="es-radio"
             v-for="(item, index) in list"
             :key="index"
             :class="{'isChecked': item.isActive}"
             @click="setActive(item.type)"
        >
           <div class="es-radio__input"></div>
           <div class="es-radio__text" v-html="item.name"></div>
        </div>

        <button class="es-button"
                :class="{'disabled': !getSelectedMethod}"
                @click="showPaymentMethod()"
        >
            Продолжить
        </button>
        <div class="select-payment__error" v-if="isErrorVisible">
            Выберите, какой картой желаете сделать оплату
        </div>
    </div>
</template>

<script>
export default {
  name: 'SelectPaymentType',
  data() {
    return {
      list: [
        {
          name: 'Оплатить с российской<br> банковской карты',
          type: 'rus',
          isActive: false,
        },
        {
          name: 'Оплатить с карты других стран',
          type: 'other',
          isActive: true,
        },
      ],
      isErrorVisible: false,
    };
  },
  computed: {
    getSelectedMethod() {
      let isSelected = false;

      this.list.forEach((item) => {
        if (item.isActive) {
          isSelected = item.type;
          this.isErrorVisible = false;
        }
      });

      return isSelected;
    },
  },
  methods: {
    showPaymentMethod() {
      if (!this.getSelectedMethod) {
        this.isErrorVisible = true;
      } else {
        this.isErrorVisible = false;
      }

      if (this.getSelectedMethod === 'rus') {
        this.$store.commit('showYaPay');
      }

      if (this.getSelectedMethod === 'other') {
        this.$store.commit('showStripePay');
      }
    },
    setActive(type) {
      this.list.forEach((item, index) => {
        const listItem = this.list[index];

        if (item.type === type) {
          listItem.isActive = true;
        } else {
          listItem.isActive = false;
        }
      });
    },
  },
  mounted() {
    this.$store.commit('showStripePay');
  },
};
</script>

<style scoped lang="scss">
 @import "../../assets/scss/common/variables";
 @import "../../assets/scss/common/forms";

.select-payment{
    padding: 20px 0;

    &__error {
        font-size: 13px;
        color: $main-red;
    }
}

 .es-button {
     margin: 30px 0 15px 0;
 }

 .es-radio {
     position: relative;
     padding-left: 30px;
     display: block;
     margin: 15px 0;
     min-height: 20px;
     cursor: pointer;

     &__text {
         font-weight: 500;
         font-size: 14px;
         line-height: 20px;
     }

     &.isChecked {
         .es-radio__input:before {
             display: block;
             content: '';
             background: $main-blue;
             width: 100%;
             height: 100%;
             left: 0;
             top: 0;
             border-radius: 50%;
         }
     }

     &__input {
         width: 20px;
         height: 20px;
         border: solid $main-blue 1px;
         display: inline-block;
         position: absolute;
         left: 0;
         top: 1px;
         border-radius: 50%;
         padding: 2px;
         box-sizing: border-box;
     }

     input {
         position: absolute;
         left: 20px;
         top: 0;
         width: 20px;
         height: 20px;
     }
 }
</style>
