<template>
    <div class="cart-list"
        :class="{'opened': isOpened}"
    >
        <div class="cart-list__title" @click="toggleList()">
            {{infoForMeditationsInCart.list.length}}
            {{wordForm(infoForMeditationsInCart.list.length, ['товар', 'товарa', 'товаров'])}}
        </div>
        <div class="cart-list__content">
            <div class="cart-list__item"
                 v-for="item in infoForMeditationsInCart.list"
                 :key="item.id"
            >
                <div class="cart-list__item-name">
                    {{item.name}}
                </div>
                <div class="cart-list__actions">
                    <button
                            class="cart-list__remove"
                            @click="removeFromCart(item.id, item.discrPrice)"
                    ></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CartProductsList',
  components: {},
  data() {
    return {
      isOpened: false,
    };
  },
  computed: {
    infoForMeditationsInCart() {
      return this.$store.getters.infoForMeditationsInCart;
    },
    promoDiscount() {
      return this.$store.state.payment.promoDiscount;
    },
    promoDiscountType() {
      return this.$store.state.payment.promoDiscountType;
    },
    activatedPromoCode() {
      return this.$store.state.payment.activatedPromoCode;
    },
  },
  methods: {
    toggleList() {
      this.isOpened = !this.isOpened;
    },
    removeFromCart(id, price) {
      this.$store.commit('removeFromCart', id);
      this.$store.commit('markMeditationForNotPayByID', id);

      let priceWithDiscount = this.infoForMeditationsInCart.priceWithSale;
      const fullPrice = this.infoForMeditationsInCart.price;

      if (this.promoDiscountType === 'publicPercent') {
        /* Костяль, не забыть убрать когда закончим с этим промокодом */
        if (
          this.activatedPromoCode.toLowerCase().replace(/\s/g, '') === 'new70'
          && this.infoForMeditationsInCart.list.length === 1
        ) {
          this.$store.commit('setPromoDiscount', 0.5);
        }

        const currentDiscount = 1 - (priceWithDiscount / fullPrice);

        if (currentDiscount > this.promoDiscount) {
          this.$store.commit('setPromoWrongActivationStatus', true);
          return;
        }

        priceWithDiscount = fullPrice - fullPrice * this.promoDiscount;
      }

      this.$store.commit('setLandingPaymentFullPrice', fullPrice);
      this.$store.commit('setLandingPaymentPriceToPay', priceWithDiscount);

      if (this.infoForMeditationsInCart.list.length === 0) {
        this.$store.dispatch('hidePayment');
        this.$store.commit('clearAllPaymentFlags', false);
      }

      const initiateEventID = Math.floor(Math.random() * (1e12 + 1));
      const currentUrl = this.$store.getters.getLocalUrl;

      if (typeof window.fbq !== 'undefined') {
        window.fbq('track', 'RemoveFromCart', {
          content_ids: [id.toString()],
          content_type: 'product',
          currency: 'USD',
          value: price,
        }, {
          eventID: initiateEventID,
        });
      }

      axios
        .post(`${currentUrl}/v2/api/sendFBServerEvent.php`, {
          amount: price,
          eventName: 'RemoveFromCart',
          email: this.getUserMail,
          id: initiateEventID,
        });
    },
    wordForm(num, word) {
      const cases = [2, 0, 1, 1, 1, 2];
      return word[(num % 100 > 4 && num % 100 < 20) ? 2 : cases[(num % 10 < 5) ? num % 10 : 5]];
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../assets/scss/common/variables";

.cart-list {
    border-top: solid 1px #E0E4F0;
    border-bottom: solid 1px #E0E4F0;
    margin-bottom: 20px;

    &__remove {
        background: url("/images/recycle-bin-icon.svg") no-repeat 50% 50%;
        width: 20px;
        height: 20px;
        border: 0;
        padding: 0;
    }

    &__title {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        padding: 10px 2px;
        position: relative;
        margin-bottom: 0;
        transition: all 200ms;

        &:after {
            position: absolute;
            right: 8px;
            top: 9px;
            cursor: pointer;
            content: '';
            display: block;
            background: url("/images/arrow-right-small.svg") no-repeat 50% 50%;
            background-size: 8px;
            width: 20px;
            height: 20px;
            transform: rotate(90deg);
            transition: all 200ms;
        }
    }

    &__content {
        padding-bottom: 0;
        max-height: 0;
        overflow: hidden;
        transition: all 200ms;
    }

    &.opened {
        .cart-list__content {
            max-height: 1000px;
            padding-bottom: 10px;
        }

        .cart-list__title {
            margin-bottom: 10px;

            &:after {
                transform: rotate(-90deg);
            }
        }
    }

    &__item {
        font-size: 12px;
        color: $lightGray;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 8px 8px 0;
    }

    &__actions {

    }
}
</style>
