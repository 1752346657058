var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-payment",class:{'opened': _vm.isVisible}},[_c('div',{staticClass:"base-payment__cover",on:{"click":_vm.hidePayment}}),_c('div',{staticClass:"base-payment__content"},[_c('div',{staticClass:"base-payment__hide-handler",on:{"click":_vm.hidePayment}}),_c('div',{staticClass:"base-payment__content-scroll"},[_c('div',{staticClass:"base-payment__title",domProps:{"innerHTML":_vm._s(_vm.getTitleText)}}),(_vm.isVisible && _vm.isSinglePagePayment)?_c('AdvantagesListSingle'):_vm._e(),(
                _vm.isVisible
                && !_vm.isSinglePagePayment
                && !_vm.isConsultationPayment
                && !_vm.isCartMeditationPagePayment
            )?_c('AdvantagesList'):_vm._e(),(_vm.isCartMeditationPagePayment)?_c('CartProductsList'):_vm._e(),(_vm.isVisible && _vm.isPromoEnabled)?_c('PromoCode'):_vm._e(),(
                        _vm.isLandingPageCoursePayment ||
                        _vm.isModulePagePayment ||
                        _vm.isSinglePagePayment ||
                        _vm.isConsultationPayment ||
                        _vm.isNewMeditationPagePayment ||
                        _vm.isCartMeditationPagePayment
                    )?_c('PriceBlockLanding'):_c('PriceBlock'),(!_vm.isStripePayVisible)?_c('SelectPaymentType'):_vm._e(),(_vm.isVisible && _vm.isStripePayVisible)?_c('CardForm'):_vm._e(),_c('div',{staticClass:"base-payment__get-troubles"},[(_vm.cardErrorText !== '')?_c('div',{staticClass:"base-payment__error"},[_vm._v(" "+_vm._s(_vm.cardErrorText)+" ")]):_vm._e(),_c('div',{staticClass:"base-payment__get-troubles-text"},[_vm._v(" Не получается оплатить? ")]),_c('div',{staticClass:"base-payment__get-troubles-descr"},[_vm._v(" Опишите проблему в директ инстаграмм @elena.siaglo ")])]),(_vm.isConsultationPayment)?_c('div',{staticClass:"base-payment__disclamer"},[_vm._v(" После оплаты напишите в директ Инстаграм @elena.siaglo. Мы с вами согласуем удобный день и время для консультации. ")]):_c('div',{staticClass:"base-payment__disclamer"},[_vm._v(" Если раньше делали покупки, войдите в "),_c('router-link',{attrs:{"to":_vm.cabinetUrl()}},[_vm._v("Личный кабинет")]),_vm._v(". Вы покупаете доступ к продуктам на сайте и соглашаетесь с "),_c('router-link',{attrs:{"to":"/agreement"}},[_vm._v("Пользовательским соглашением.")]),_vm._v(" Доступ появляется сразу после оплаты и остается навсегда. Скачать медитации и вебинары нельзя, слушать надо только через сайт. Комиссия за платеж не берется. ")],1)],1)]),_c('YaCard')],1)}
var staticRenderFns = []

export { render, staticRenderFns }