<template>
    <div class="promo-code">
        <div class="promo-code__handler"
             v-if="!isFormVisible && !promoDiscount"
             @click="showForm()"
        >
            У вас есть промокод?
        </div>

        <form class="promo-code__form"
              v-if="isFormVisible || promoDiscount"
              :class="{'green': isSuccessVisible, 'red': error}"
              @submit.prevent="checkPromoCode()"
        >
            <template v-if="isSuccessVisible">
                <div class="promo-code__success">Промокод активирован</div>
                <div class="promo-code__success-descr">
                    Цена заказа уменьшилась <br>
                    <template v-if="discountType === 'publicPercent'">
                        на {{promoDiscount * 100}}%
                    </template>
                    <template v-else>
                        {{discountValueText}}
                        {{showDiscountValue}} руб.
                    </template>
                </div>
                <div class="promo-code__success-disclaimer">
                    Чтобы воспользоваться скидкой,
                    оплатите заказ сейчас.
                </div>
            </template>
            <template v-else-if="isPromoNotActivated">
                <button type="button" class="promo-code__close-btn" @click="hideForm()"></button>
                <div class="promo-code__error">Промокод не активировался</div>
                <div class="promo-code__error-descr">
                    Ваша текущая скидка больше, чем по промокоду {{promoDiscount * 100}}%
                </div>
                <div class="promo-code__error-disclaimer" v-if="!localError">
                   Что бы применилась ваша текущая скидка, оплатите заказ сейчас
                </div>
                <div class="promo-code__form-title" v-else>У вас есть промокод?</div>
                <div class="es-input-cover">
                    <input type="text"
                           class="es-input"
                           placeholder="Введите и нажмите стрелку"
                           autofocus
                           v-model="code"
                    >

                    <button type="submit" class="es-button promo-code__check" :disabled="loading">
                    </button>
                </div>
            </template>
            <template v-else>
                <button type="button" class="promo-code__close-btn" @click="hideForm()"></button>
                <template v-if="error || localError">
                    <div class="promo-code__error">{{errorTitle}}</div>
                    <div class="promo-code__error-descr">{{errorMessage}}</div>
                    <div class="promo-code__error-disclaimer" v-if="!localError">
                        {{localErrorMessage}}
                    </div>
                </template>
                <div class="promo-code__form-title" v-else>У вас есть промокод?</div>
                <div class="es-input-cover">
                    <input type="text"
                           class="es-input"
                           placeholder="Введите и нажмите стрелку"
                           autofocus
                           v-model="code"
                    >

                    <button type="submit" class="es-button promo-code__check" :disabled="loading">
                    </button>
                </div>
            </template>

        </form>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PromoCode',
  components: {},
  data() {
    return {
      isFormVisible: false,
      code: '',
      success: false,
      error: false,
      errorMessage: '',
      errorTitle: 'Промокод не подошел',
      localErrorMessage: 'Промокод не подошел',
      loading: false,
      promoDiscountLocal: 0,
      discountType: '',
    };
  },
  computed: {
    discountValueText() {
      return this.promoDiscountType === 'finalParice' ? 'до' : 'на';
    },
    showDiscountValue() {
      let result = 0;

      if (this.promoDiscountValue) {
        result = this.promoDiscountValue;
      } else {
        result = this.promoDiscount;
      }

      return result;
    },
    promoDiscount() {
      return this.$store.state.payment.promoDiscount;
    },
    promoDiscountValue() {
      return this.$store.state.payment.promoDiscountValue;
    },
    minTotalForPromo() {
      return this.$store.state.payment.minTotalForPromo;
    },
    isPromoNotActivated() {
      return this.$store.state.payment.isPromoNotActivated;
    },
    isSuccessVisible() {
      if (!this.isFullPackageSelected && this.promoDiscount > 1) {
        return false;
      }

      if (!this.promoDiscount) {
        return false;
      }

      if (this.isPromoNotActivated) {
        return false;
      }

      if (this.priceWithoutDiscount < this.minTotalForPromo) {
        return false;
      }

      return true;
    },
    localError() {
      let localError = false;

      if (this.success && !this.isFullPackageSelected && this.promoDiscount > 1) {
        localError = true;
      }
      if (this.success && this.priceWithoutDiscount < this.minTotalForPromo) {
        localError = true;
      }

      if (this.isPromoNotActivated) {
        localError = true;
      }

      return localError;
    },
    isFullPackageSelected() {
      return this.$store.state.payment.isFullPackageSelected;
    },
    priceWithoutDiscount() {
      return this.$store.state.payment.priceWithoutDiscount;
    },
    promoDiscountType() {
      return this.$store.state.payment.promoDiscountType;
    },
    cartList() {
      return this.$store.state.meditations.cartList;
    },
  },
  watch: {
    localError() {
      if (this.success && !this.isFullPackageSelected) {
        this.errorMessage = 'Промокод можно применить только при покупке курса.';
        this.error = true;
      } else if (this.priceWithoutDiscount < this.minTotalForPromo) {
        this.errorMessage = `Этот промокод можно применить
                             только для суммы свыше ${this.minTotalForPromo} руб.`;
        this.error = true;
      } else if (this.isPromoNotActivated) {
        this.error = true;
      } else {
        this.error = false;
      }

      this.$store.commit('setLocalPromoError', this.localError);
    },
    isFullPackageSelected() {
      this.hideForm();
    },
  },
  methods: {
    showForm() {
      this.isFormVisible = true;
    },
    hideForm() {
      this.success = false;
      this.isFormVisible = false;
      this.error = false;
      this.$store.commit('setPromoDiscount', 0);
      this.$store.commit('setPromoDiscountValue', 0);
      this.$store.commit('setLocalPromoError', false);
    },
    clearData() {
      this.$store.commit('setPromoDiscount', 0);
      this.$store.commit('setPromoDiscountValue', 0);
    },
    checkPromoCode() {
      const currentUrl = this.$store.getters.getLocalUrl;
      this.loading = true;
      this.clearData();
      axios
        .post(`${currentUrl}/v2/api/checkPromoCode.php`, {
          code: this.code,
        })
        .then((response) => {
          if (response.data.success) {
            let minTotal = response.data.type === 'private' ? 1200 : 2950;
            let discountValue = parseInt(response.data.discount, 10);

            if (
              response.data.type === 'publicPercent'
              && this.code.toLowerCase().replace(/\s/g, '') === 'new70'
              && this.cartList.length < 2
            ) {
              this.errorMessage = 'Промокод действует при покупке 2-х и более товаров';
              this.localErrorMessage = 'Добавьте еще один товар в корзину, чтобы активировать промокод';
              this.errorTitle = 'Промокод не активировался';
              this.error = true;

              return;
            }

            if (response.data.type === 'publicPercent') {
              minTotal = 0;
              discountValue /= 1000;
            }

            if (response.data.type === 'publicWebinarSale') {
              minTotal = 0;
            }

            this.discountType = response.data.type;

            this.error = false;
            this.success = response.data.success;
            this.$store.commit('setPromoDiscountType', response.data.type);
            this.$store.commit('setPromoDiscount', discountValue);
            this.$store.commit('setPromoCode', this.code);
            this.$store.commit('changeMinTotalForPromo', minTotal);
          } else {
            this.errorMessage = response.data.errorText;
            this.errorTitle = 'Промокод не подошел';
            this.localErrorMessage = 'Возможно, вы допустили ошибку\n'
              + '                        при написании, попробуйте еще раз';
            this.error = true;
          }
        }).finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../assets/scss/common/variables";
 @import "../../assets/scss/common/forms";

.promo-code {
    text-align: center;
    margin-bottom: -10px;

    &__check {
        position: absolute;
        right: 10px;
        top: 0;
        width: 24px;
        height: 24px!important;
        padding: 0;
        border-radius: 50%;
        flex-shrink: 0;
        background: url("/images/arrow-white.svg") no-repeat 50% 50% $main-blue;
    }

    &__handler {
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        border-bottom: dashed 1px $black;
        display: inline-block;
    }

    &__success {
        font-size: 12px;
        color: $main-green;
        font-weight: 600;
    }

    &__success-descr {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin: 15px 0;
    }

    &__success-disclaimer {
        font-size: 10px;
        line-height: 17px;
        color: $darkGray;
        padding: 0 40px;
    }

    &__error {
        font-size: 12px;
        color: $main-red;
        font-weight: 600;
    }

    &__error-descr {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin: 15px 0;
        padding: 0 40px;
    }

    &__error-disclaimer {
        font-size: 10px;
        line-height: 17px;
        color: $darkGray;
        padding: 0 40px;
    }

    &__form {
        display: block;
        background: $main-blue;
        border-radius: 10px;
        padding: 16px;
        position: relative;
        margin: 15px 0 0 0;
        border: solid 1px transparent;

        &.green {
            border-color: $main-green;
            background: $white;
        }

        &.red {
            border-color: $main-red;
            background: $white;
        }

        input[type=text].es-input {
            text-align: left;
            font-size: 22px;
            height: 44px;
            padding-bottom: 2px;
            line-height: 42px;

            &::placeholder {
              font-size: 14px;
              color: $black;
            }
        }

        .es-button {
          height: 44px;
        }
    }

    &__form-title {
        text-align: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: $white;
    }

    &__close-btn{
        width: 24px;
        height: 24px;
        background: transparent;
        border-radius: 50%;
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 10px;
        z-index: 7;
        border: 0;
        padding: 0;

        &:before {
            width: 24px;
            height: 24px;
            display: flex;
            background:
                    url("/images/close-icon-white.svg") no-repeat 50% 50%;
            content: '';
            background-size: 8px;
        }
    }
}
</style>
