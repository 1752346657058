<template>
    <div class="base-payment" :class="{'opened': isVisible}">
        <div class="base-payment__cover" @click="hidePayment"></div>
        <div class="base-payment__content">
            <div class="base-payment__hide-handler" @click="hidePayment"></div>
            <div class="base-payment__content-scroll">
                <div class="base-payment__title" v-html="getTitleText"></div>
                <AdvantagesListSingle
                        v-if="isVisible && isSinglePagePayment"
                ></AdvantagesListSingle>
                <AdvantagesList v-if="
                    isVisible
                    && !isSinglePagePayment
                    && !isConsultationPayment
                    && !isCartMeditationPagePayment
                "></AdvantagesList>
                <CartProductsList
                        v-if="isCartMeditationPagePayment"
                ></CartProductsList>
                <PromoCode v-if="isVisible && isPromoEnabled"></PromoCode>
                <PriceBlockLanding
                        v-if="
                            isLandingPageCoursePayment ||
                            isModulePagePayment ||
                            isSinglePagePayment ||
                            isConsultationPayment ||
                            isNewMeditationPagePayment ||
                            isCartMeditationPagePayment
                        "
                ></PriceBlockLanding>
                <PriceBlock v-else></PriceBlock>

                <SelectPaymentType v-if="!isStripePayVisible"></SelectPaymentType>

                <CardForm v-if="isVisible && isStripePayVisible"></CardForm>

                <div class="base-payment__get-troubles">
                    <div class="base-payment__error"
                         v-if="cardErrorText !== ''"
                    >
                        {{cardErrorText}}
                    </div>
                    <div class="base-payment__get-troubles-text">
                        Не получается оплатить?
                    </div>
                    <div class="base-payment__get-troubles-descr">
                        Опишите проблему в директ инстаграмм @elena.siaglo
                    </div>
                </div>
                <div class="base-payment__disclamer" v-if="isConsultationPayment">
                    После оплаты напишите в директ Инстаграм @elena.siaglo.
                    Мы с вами согласуем удобный день и время для консультации.
                </div>
                <div class="base-payment__disclamer" v-else>
                    Если раньше делали покупки, войдите в
                    <router-link :to="cabinetUrl()">Личный кабинет</router-link>.
                    Вы покупаете доступ к продуктам на сайте и соглашаетесь с
                    <router-link to="/agreement">Пользовательским соглашением.</router-link>
                    Доступ появляется сразу после оплаты и остается навсегда.
                    Скачать медитации и вебинары нельзя, слушать надо только через сайт.
                    Комиссия за платеж не берется.
                </div>
            </div>
        </div>
        <YaCard></YaCard>
    </div>
</template>

<script>
import CardForm from './CardForm.vue';
import PriceBlock from './PriceBlock.vue';
import PriceBlockLanding from './PriceBlockLanding.vue';
import AdvantagesList from './AdvantagesList.vue';
import CartProductsList from './CartProductsList.vue';
import AdvantagesListSingle from './AdvantagesListSingle.vue';
import PromoCode from './PromoCode.vue';
import YaCard from './YaCard.vue';
import SelectPaymentType from './SelectPaymentType.vue';

export default {
  name: 'BasePayment',
  components: {
    CardForm,
    PriceBlock,
    AdvantagesList,
    CartProductsList,
    YaCard,
    PromoCode,
    PriceBlockLanding,
    AdvantagesListSingle,
    SelectPaymentType,
  },
  data() {
    return {
      complete: false,
      fullText: 'Получите все товары курса по скидке',
      fullTextNotPayed: 'Получите недостающие товары курса по скидке',
      singleText: 'Получите этот товар без скидки',
      isUserAuthed: this.$store.getters.getUserMail !== '',
    };
  },
  computed: {
    isPromoEnabled() {
      return this.landingPaymentType !== 'premium' && this.landingPaymentType !== 'cabinetConsultation';
    },
    getStripeKey() {
      return 'pk_test_EbFCyRpagCLakgHBurKM1Hkk';
    },
    isVisible() {
      return this.$store.state.payment.isOpened;
    },
    isStripePayVisible() {
      return this.$store.state.payment.isStripePayVisible;
    },
    isFullPackageSelected() {
      return this.$store.state.payment.isFullPackageSelected;
    },
    meditationInfo() {
      return this.$store.getters.getMeditationsInfo;
    },
    cardErrorText() {
      return this.$store.state.payment.cardErrorText;
    },
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
    isLandingPageCoursePayment() {
      return this.$store.getters.isLandingPageCoursePayment;
    },
    isSinglePagePayment() {
      return this.$store.state.payment.isSinglePagePayment;
    },
    singleMeditationsCount() {
      return this.$store.getters.getMeditationsInfoForSingle.pendingMeditations.length;
    },
    isConsultationPayment() {
      return this.$store.state.payment.isConsultationPayment;
    },
    isModulePagePayment() {
      return this.$store.state.payment.isModulePagePayment;
    },
    isNewMeditationPagePayment() {
      return this.$store.state.payment.isNewMeditationPagePayment;
    },
    isCartMeditationPagePayment() {
      return this.$store.state.payment.isCartMeditationPagePayment;
    },
    isModulePaymentFull() {
      return this.$store.state.payment.isModulePaymentFull;
    },
    landingPaymentType() {
      return this.$store.state.payment.landingPaymentType;
    },
    landingPaymentFullPrice() {
      return this.$store.state.payment.landingPaymentFullPrice;
    },
    landingPaymentPriceToPay() {
      return this.$store.state.payment.landingPaymentPriceToPay;
    },
    isSaleEnabled() {
      return this.landingPaymentFullPrice > this.landingPaymentPriceToPay;
    },
    getTitleText() {
      let finalTitle = '';
      let titlePostfix = '';
      let titlePrefix = 'Получите тариф';

      if (this.isFullPackageSelected) {
        finalTitle = this.meditationInfo.hasPayed ? this.fullTextNotPayed : this.fullText;
      } else {
        finalTitle = this.singleText;
      }

      if (this.isUserAuthed) {
        titlePrefix = 'Докупите до полного курса тариф';
      }

      if (this.isSaleEnabled) {
        titlePostfix = 'по выгодной цене';
      }

      if (this.isLandingPageCoursePayment) {
        finalTitle = `${titlePrefix} "Стандарт" ${titlePostfix}`;
      }

      if (this.isLandingPageCoursePayment && this.landingPaymentType === 'premium') {
        finalTitle = `${titlePrefix} "Расширенный" ${titlePostfix}`;
      }

      if (this.isModulePagePayment && this.isModulePaymentFull) {
        finalTitle = 'Получите модуль по выгодной цене';
      }

      if (this.isModulePagePayment && !this.isModulePaymentFull) {
        finalTitle = 'Докупите до полного модуль по выгодной цене';
      }

      if (this.isSinglePagePayment) {
        finalTitle = 'Купите полную версию медитации по скидке и слушайте всегда';
      }

      if (this.isSinglePagePayment && this.singleMeditationsCount > 1) {
        finalTitle = 'Купите полную версию медитаций по скидке и слушайте всегда';
      }

      if (this.isConsultationPayment) {
        finalTitle = 'Получите 1 консультацию с психологом в удобное для вас время';
      }

      if (this.isCartMeditationPagePayment) {
        finalTitle = 'Оформление заказа';
      }

      return finalTitle;
    },
  },
  mounted() {
    this.setCustomVh();
  },
  methods: {
    setCustomVh() {
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      document.documentElement.style.setProperty('--custom-vh', `${vh}px`);
    },
    hidePayment() {
      this.$store.dispatch('hidePayment');
      this.$store.commit('clearAllPaymentFlags', false);
    },
    showYaPay() {
      this.$store.commit('showYaPay');
    },
    cabinetUrl() {
      let url;

      if (this.getUserMail === '') {
        url = '/auth';
      } else {
        url = '/my';
      }

      return url;
    },
  },
};
</script>

<style lang="scss">
 @import "../../assets/scss/common/variables";

.base-payment {
    &.opened {
        .base-payment__cover {
            opacity: 1;
            transition: opacity 200ms;
            visibility: visible;
        }

        .base-payment__content {
            transform: translate(0);
        }
    }
    &__title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 24px;
        line-height: 20px;
    }
    &__hide-handler {
        background: url("/images/hide-cover-handler.svg") 50% 50% no-repeat;
        width: 100%;
        height: 40px;
    }
    &__cover {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background: rgba($black, 0.78);
        z-index: 41;
        opacity: 0;
        visibility: hidden;
    }

    &__content {
        background: $light-blue;
        border-radius: 20px 20px 0 0;
        bottom: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        padding: 0 24px 0 24px;
        position: fixed;
        z-index: 43;
        transition: transform 200ms;
        transform: translate(0, 100%);
        max-width: 767px;

        /* ipad */
        @media only screen and (min-width: 768px) {
            left: calc(50% - 384px);
        }
    }

    &__content-scroll {
        margin: 0 -20px;
        padding: 0 20px 24px 20px;
        max-height: calc(var(--custom-vh) - 100px);
        overflow-y: auto;
        overflow-x: hidden;

        @media only screen and (min-width: 768px) {
            max-width: 462px;
            margin: auto;
        }

      /*iphone 6 и другие с хорошой шириной экрана но высотой меньше чем у iphone 11*/
      @media only screen and (max-height: 700px) and (min-width: 374px) {
        max-height: calc(var(--custom-vh) - 80px);
      }

      /*iphone 5*/
        @media only screen and (max-width: 374px) {
            max-height: calc(var(--custom-vh) - 70px);
        }
    }

    &__disclamer {
        font-size: 10px;
        text-align: center;
        line-height: 17px;

        a {
            color: $black;
        }
    }

    &__get-troubles {
        line-height: 20px;
        margin: 16px -24px;
        padding: 15px 24px;
        background: rgba($main-blue, 0.05);
        text-align: center;
        font-size: 12px;

        &:empty {
            padding: 0;
            margin-bottom: 20px;
        }
    }

    &__get-troubles-text {
        font-weight: 600;
        color: $black;
        font-size: 14px;
        margin-bottom: 5px;
    }

    &__get-troubles-descr {
        font-size: 11px;
        color: $black;
    }

    &__error {
        color: $main-red-bright;
        text-decoration: none;
        margin-bottom: 5px;

        &:before {
            background: url(/images/error-icon.svg) no-repeat;
            background-size: contain;
            content: '';
            display: inline-block;
            width: 13px;
            height: 13px;
            vertical-align: top;
            margin-right: 10px;
            position: relative;
            top: 2px;
        }
    }
}
</style>
