<template>
  <div class="ya-form" :class="{'ya-form--visible': isVisible, 'lrgMarginLeft': isMNJPage}">
    <div class="ya-form__back" @click="closeYaPay"></div>

    <div class="ya-form__loading" v-if="loading">
      <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                <rect x="20" y="50" width="4" height="10" fill="#000">
                  <animateTransform attributeType="xml"
                    attributeName="transform" type="translate"
                    values="0 0; 0 20; 0 0"
                    begin="0" dur="0.6s" repeatCount="indefinite" />
                </rect>
                <rect x="30" y="50" width="4" height="10" fill="#000">
                  <animateTransform attributeType="xml"
                    attributeName="transform" type="translate"
                    values="0 0; 0 20; 0 0"
                    begin="0.2s" dur="0.6s" repeatCount="indefinite" />
                </rect>
                <rect x="40" y="50" width="4" height="10" fill="#000">
                  <animateTransform attributeType="xml"
                    attributeName="transform" type="translate"
                    values="0 0; 0 20; 0 0"
                    begin="0.4s" dur="0.6s" repeatCount="indefinite" />
                </rect>
            </svg>

      <div class="map-preview-3d__loader-text" v-if="loading">Загрузка платежной формы</div>
    </div>

    <div id="ya-payment-form"></div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CardForm',
  data: () => ({
    complete: false,
    loading: false,
    paymentId: '',
  }),
  mounted() {
  },
  methods: {
    closeYaPay() {
      this.$store.commit('hideYaPay');
    },
  },
  computed: {
    isVisible() {
      return this.$store.state.payment.isYaPayVisible;
    },
    webinarPaymentData() {
      return this.$store.state.payment.webinarPaymentData;
    },
    preparedInfo() {
      return this.$store.getters.getMeditationsInfoForCourse;
    },
    readyToPayIds() {
      return this.$store.getters.getReadyToPayIds;
    },
    getReadyToPayIdsData() {
      return this.$store.getters.getReadyToPayIdsData;
    },
    isMNJPage() {
      return this.$route.name === 'Home' || this.$route.name === 'WebinarLanding';
    },
    isSingleDigitalSale() {
      return this.$route.name === 'TelegramMeeting'
        || this.$route.name === 'WebinarLanding'
        || this.$route.name === 'ConsultatantPayment';
    },
    isCoursePage() {
      return this.$route.name === 'WelcomeWebinar'
        || this.$route.name === 'Home'
        || this.$route.name === 'WelcomeWebinar36';
    },
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
    coursePaymentType() {
      return this.$store.state.payment.landingPaymentType;
    },
    landingPaymentFullPrice() {
      return this.$store.state.payment.landingPaymentFullPrice;
    },
    landingPaymentPriceToPay() {
      return this.$store.state.payment.landingPaymentPriceToPay;
    },
    saleCoof() {
      return this.landingPaymentPriceToPay / this.landingPaymentFullPrice;
    },
    exchangeRateRUB() {
      return this.$store.state.general.exchangeRateRUB;
    },
    priceToPay() {
      return this.$store.state.payment.priceToPay;
    },
    paymentCarrency() {
      return this.$store.state.payment.paymentCarrency;
    },
  },
  watch: {
    isVisible(val) {
      document.querySelector('#ya-payment-form').innerHTML = '';

      if (val) {
        this.loading = true;
        const currentUrl = this.$store.getters.getLocalUrl;
        let amount = this.priceToPay;

        if (this.paymentCarrency !== 'RUB') {
          amount = Math.round(amount * this.exchangeRateRUB);
        }

        axios
          .post(`${currentUrl}/v2/api/yandexGetPaymentToken.php`, {
            amount,
            email: this.getUserMail,
            promoCode: this.$store.state.payment.activatedPromoCode,
            coursePaymentType: this.coursePaymentType,
            payedItems: JSON.stringify(this.getReadyToPayIdsData),
          })
          .then((response) => {
            if (response.data.success) {
              const checkout = new window.YooMoneyCheckoutWidget({
                confirmation_token: response.data.confirmation_token,
                error_callback() {
                  this.loading = false;
                },
              });

              this.paymentId = response.data.id;

              checkout.render('ya-payment-form').then(() => {
                this.loading = false;
              });

              /* Для формирования чека получим сумму со скидкой по кадому товару */
              let readyToPayIdsDataWithSale = this.getReadyToPayIdsData
                .map((item) => {
                  const newItem = item;
                  let priceWithSale = 0;

                  if (this.saleCoof !== 1) {
                    priceWithSale = item.price - (item.price * this.saleCoof);
                  } else {
                    priceWithSale = item.price;
                  }

                  if (this.paymentCarrency !== 'RUB') {
                    newItem.priceWithSale = Math.round(priceWithSale * this.exchangeRateRUB);
                    newItem.price = Math.round(item.price * this.exchangeRateRUB);
                  } else {
                    newItem.priceWithSale = priceWithSale;
                    newItem.price = item.price;
                  }

                  return newItem;
                });

              /* Для покупкпки курса сформируем отдельный пункт в чеке */
              if (this.isCoursePage) {
                const meditationCount = readyToPayIdsDataWithSale.length;

                readyToPayIdsDataWithSale = [{
                  name: `Курс из ${meditationCount} медитаций`,
                  price: amount,
                  priceWithSale: amount,
                }];
              }

              /* Для покупкпки какого либо вебинара сформируем отдельный пункт в чеке */
              if (this.isSingleDigitalSale) {
                readyToPayIdsDataWithSale = [{
                  name: 'Участие в медитации',
                  price: amount,
                  priceWithSale: amount,
                }];
              }

              checkout.on('success', () => {
                axios
                  .post(`${currentUrl}/v2/api/yandexGetPaymentReceipt.php`, {
                    amount,
                    email: this.getUserMail,
                    promoCode: this.$store.state.payment.activatedPromoCode,
                    coursePaymentType: this.coursePaymentType,
                    payedItems: JSON.stringify(readyToPayIdsDataWithSale),
                    paymentID: this.paymentId,
                  })
                  .then((responseReciept) => {
                    if (!responseReciept.data.success) {
                      this.$store.commit('showGlobalError', 'Чек не сформирован, обратитесь к менеджеру.');
                    }
                  });

                if (this.$route.name === 'WebinarLanding') {
                  this.$store.commit('setWebinarPaymentData', {
                    userName: this.webinarPaymentData.userName,
                    userEmail: this.webinarPaymentData.userEmail,
                  });

                  this.$store.dispatch('addUserToWebinar');
                  this.$router.push({ name: 'WebinarFlowSuccess' });
                } else if (this.$route.name === 'ConsultatantPayment') {
                  this.$router.push({ name: 'ConsultatantPayment', query: { success: 'true' } });
                } else if (this.$route.name === 'TelegramMeeting') {
                  this.$store.dispatch('saveTelegramUser');
                  this.$router.push({ name: 'TelegramFlowSuccess' });
                } else {
                  const ids = this.readyToPayIds.join();
                  const packageName = this.$store.state.payment.landingPaymentType;

                  this.$store.dispatch('savePayedData', { ids, packageName });
                  this.$store.dispatch('showSuccessResult');
                }

                this.$store.commit('hideYaPay');
                this.$store.dispatch('hidePayment');
                this.$store.commit('hidePlayer');
              });
            }
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/common/variables";

.ya-form {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: $white;
  max-width: 640px;
  z-index: 50;
  padding: 15px 10px 10px 10px;
  box-sizing: border-box;
  transition: all 200ms;
  transform: translate(0, 100%);
  overflow: auto;
  margin-left: -12px;

    @media only screen and (min-width: 768px) {
        max-width: 768px;
        margin-left: -24px;
    }

  &.lrgMarginLeft {
      margin-left: -24px;
  }

  &__loading {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 300px;
    position: absolute;
    width: 100%;
    text-align: center;
    margin-top: 20px;

    svg {
      position: relative;
      left: 15px;
      width: 80px;
    }
  }

  &__back {
      width: 24px;
      height: 24px;
      background: $main-blue;
      border-radius: 50%;
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 18px;

      &:before {
          width: 100%;
          height: 100%;
          display: flex;
          background: url("/images/close-icon-white.svg") no-repeat 50% 50%;
          content: '';
          background-size: 8px;
      }
  }

  &--visible {
    transform: translate(0);
  }

  #ya-payment-form {
    position: relative;
    z-index: 2;
    margin-top: 60px;
  }
}
</style>
